import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../../composants/image/Image"
import LayoutTutorielEN from "../../../composants/layout/en/LayoutTutorielEN"
import Lien from "../../../composants/navigation/Lien"
import Section from "../../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageGuideAuroresBoreales: allPagesCompilationYaml(
      filter: { code: { eq: "guideAuroresBorealesEN" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "comprendreAuroresBorealesEN"
            "photographierAuroresBorealesEN"
            "galerieAuroresBorealesEN"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["aurorePlageAbandonnee", "auroreTaiga"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesObservationduneAuroreBoreale"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesUntrouCoronal"
            "auroresBorealesIndiceKp"
            "auroresBorealesUnovaleAuroral"
          ]
        }
      }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroresBorealesArcraye"
            "auroresBorealesPhaseactive"
            "auroresBorealesAurorecoronale"
          ]
        }
      }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function NorthernLightsObservationGuide() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageGuideAuroresBoreales")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPortraitMoyen"
  )

  return (
    <LayoutTutorielEN
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Preamble">
        <p>
          This guide is the fruit of my nights spent under the northern lights.
          I hope that it is complete enough and that it will give you answers to
          the questions you will ask yourself to prepare your trip…
        </p>
        <p>
          Also I suggest you to read this article first: “
          <Lien codePageCible="comprendreAuroresBorealesEN" />
          ”.
        </p>
      </Section>
      <Section titre="Where?">
        <h3>Auroral ovals</h3>
        <p>
          Seen from space, northern lights take the form of {""}
          <strong>ovals</strong> centered around terrestrial magnetic poles.
          They are 500 to 1000 kilometers wide. During an average activity (the
          most common), the ovals form around a latitude between{" "}
          <strong>60° and 70° north</strong>.
        </p>
        <p>
          If the activity is stronger, their diameters increase and they go down
          more to the south. Therefore :
        </p>
        <ul>
          <li>
            During strong activity, an observer who is too far north will see
            the aurora on the southern horizon.
          </li>
          <li>
            During normal activity, an observer located too far south will see
            the aurora on the northern horizon.
          </li>
        </ul>
        <Image
          image={obtenirImage(images, "auroresBorealesUnovaleAuroral")}
          langue={page.langue}
        />
        <h3>Where to travel?</h3>
        <p>
          During moderate activity, all{" "}
          <strong>regions below the auroral oval</strong> are ideal (60° - 70°
          north approximately):
        </p>
        <ul>
          <li>Northern Norway (Tromsø region).</li>
          <li>Lapland.</li>
          <li>Iceland.</li>
          <li>Siberia.</li>
          <li>Canada, Alaska.</li>
        </ul>
        <h3>Norway, Lapland or Iceland?</h3>
        <p>It all depends on the purpose of your trip:</p>
        <ul>
          <li>
            Northern Norway and Lapland (Swedish or Finnish) are the most
            suitable regions for observing intense and “normal” auroras. In{" "}
            <strong>Tromsø</strong> region, which is located 5° further north
            than Iceland, you will see a normal activity aurora at the zenith.
            Which is already very spectacular. The climate there is also very
            mild for the latitude. As long as you stay close to the coasts,
            temperatures above 0° are common. Be careful in Lapland, the
            temperatures can blithely go down below <strong>-30°</strong>: get
            out well covered… (see “Personal equipment”).
          </li>
          <li>
            Iceland is further south than Norway: normal auroral activity will
            not be observed at the zenith, where it is spectacular. It will be a
            bit lower on the northern horizon. However, due to the island’s
            great volcanic past (and present), the landscapes are breathtaking.
            However, be careful with the climate: it is rougher than Norway
            (wind).
          </li>
        </ul>
        <p>
          One last thing to consider: <strong>Iceland</strong> has become very,{" "}
          <strong>very busy</strong> in recent years (the country is
          increasingly subject to a touristic overload, the authorities are
          working on the problem). Choose distant places north of the country to
          be quiet.
        </p>
        <Image
          image={obtenirImage(images, "auroreTaiga")}
          langue={page.langue}
        />
      </Section>
      <Section titre="When?">
        <h3>Period of the year</h3>
        <p>
          I will only consider Scandinavia because it is the region that I know
          the best. There, the northern lights season begins end of September
          and finishes at the end of March.
        </p>
        <ul>
          <li>
            October is a good month to go: the number of clear nights
            (cloudless) is the most important of the year. On the other hand,
            you will not yet benefit from the snow-covered scenery that makes
            the region so charming.
          </li>
          <li>
            Avoid the month of December: it is the polar night (but during the
            day, it is not so dark, it is like dusk).
          </li>
          <li>
            For a photographer, the month of January is interesting: days are
            getting longer, the Sun is rising on the horizon and hugs him for
            hours. The lights are incredible, but the days remain very short.
          </li>
          <li>
            The February-March months are a good compromise between lights,
            sunshine, dawn, climatic conditions and winter scenery.
          </li>
          <li>
            The two months of the equinoxes (September and March) are the most
            favorable to see powerful auroras due to numerous faults in the
            Earth’s magnetosphere (the{" "}
            <Lien urlExterne="http://spaceweather.com/archive.php?view=1&day=11&month=03&year=2018">
              Russel – McPherron
            </Lien>{" "}
            phenomenon).
          </li>
        </ul>
        <h3>The Moon</h3>
        <p>
          Prefer nights <strong>without Moon</strong> to enjoy auroras. A slight
          crescent, however, can be useful for photographers: it will reveal the
          landscapes on your photos. My favorite nights are the one around the
          week of the new Moon.
        </p>
        <h3>
          The maximum of the solar cycle has passed. Can I hope to see northern
          lights this winter?
        </h3>
        <p>
          Yes! The solar cycle is only related to the number of spots that
          appear on the surface of the Sun, which reaches its maximum every 11
          years or so.
        </p>
        <p>
          However, contrary to popular belief, the spots are not the the only
          solar events to be at the origin of northern lights. There are also
          the <strong>coronal holes</strong> (see the article “
          <Lien codePageCible="comprendreAuroresBorealesEN" />
          ”). The formation of these holes is independent of the solar cycle and
          they appear very regularly on the surface of the Sun.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesUntrouCoronal")}
          langue={page.langue}
        />
        <h3>At what time of the day?</h3>
        <p>
          It is <strong>pointless</strong> to choose a period of polar night to
          have a better chance to see northern lights. The explanation is that
          the Earth revolves around its geographic north pole while the auroral
          oval is centered around the north magnetic pole (the 2 poles are
          separated by approximately 8°). Consequently, all the favorable
          observation regions are not always located under the auroral oval.
        </p>
        <p>
          The following animation helps us understand the phenomenon (source: K.
          Akasofu -{" "}
          <Lien urlExterne="http://asahi-classroom.gi.alaska.edu/index.htm">
            Asahi aurora classroom
          </Lien>
          ):
        </p>
        <div className="conteneur-video">
          <iframe
            src="https://player.vimeo.com/video/474171164"
            frameBorder="0"
            height="80%"
            width="300px"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <p>
          We can see that the pink point in Alaska enters the auroral oval
          around 8 p.m. and get out of it around 4 a.m. The times will be the
          same whatever the country you are in: the auroral oval remains static,
          it does not revolve around the magnetic north pole. Therefore, be
          ready from 8 p.m. in the evening: this is the entrance time under the
          auroral oval.
        </p>
        <p>
          Also, do not go to bed before midnight: you will be in the heart of
          the most active region of the oval. Even though the aurora can happen
          quite early in the evening, force yourself to eat well to hold all
          night in case of intense show.
        </p>
      </Section>
      <Section titre="Personal equipment">
        <p>
          Watching the aurora is a static activity: we don’t move a lot during
          several hours. You will not enjoy the show if you are cold too
          quickly.
        </p>
        <h3>Body</h3>
        <p>
          A good <strong>ski</strong> equipment is enough. In case of intense
          cold, you will multiply the layers: ski underwear, various fleeces,
          hot ski pants…
        </p>
        <h3>Extremities</h3>
        <p>
          Invest to cover the extremities of your body, this is where the
          greatest heat loss happens.
        </p>
        <ul>
          <li>
            <strong>Head</strong>: invest in a big beanie or a chapka.
          </li>
          <li>
            <strong>Hands</strong>: protected by a pair of warm mittens, or ski
            gloves covered with over-gloves in which you will put a heater. You
            can also take under gloves: they will protect you from the cold when
            you take off your gloves to handle your camera.
          </li>
          <li>
            <strong>Feet</strong>: this is where you will feel the cold{" "}
            <strong>invade you</strong>. Avoid summer hike shoes: they are not
            hot enough and they will not protect you against the very cold snow
            moisture. Invest instead in a good pair of après-ski, or better, in
            a pair of Sorel Caribou.
          </li>
        </ul>
        <h3>Other accessories</h3>
        <p>
          Always carry with you <strong>a pair of crampons</strong> (sold for
          ten euros in supermarkets): they will avoid you slips and bad falls if
          there is ice on the ground (or if it is hidden under the snow).
        </p>
        <p>
          Take a few <strong>heaters</strong> (hands and feet above all). Avoid
          rechargeable heaters in water boiling. You will forget to recharge
          them and they are few comfortable (they are very hard). Disposable
          models will do just fine.
        </p>
        <p>
          Finally, do not forget a headlamp. Choose one with{" "}
          <strong>red lighting</strong> to keep your night vision and not dazzle
          your observation mates.
        </p>
      </Section>
      <Section titre="Wheather forecast">
        <p>
          Northern lights is a high altitude phenomenon, which occurs {""}
          <strong> well beyond the clouds </strong> (from 70 kilometers
          altitude). Therefore, you have to watch for the weather forecast as
          much as the solar storm forecast.
        </p>
        <p>
          But do not despair if an evening starts under the clouds while the
          solar storm forecast is good: the weather can change quickly on the
          Norwegian coast. So watch the satellites animations to see if cloudy
          gaps are heading towards you (see the links at the end of the
          article).
        </p>
      </Section>
      <Section titre="Travel and traffic conditions">
        <p>Before moving, watch over:</p>
        <ul>
          <li>
            <strong>The weather</strong>: anticipate complicated conditions:
            snowstorm, strong wind…
          </li>
          <li>
            <strong>Traffic conditions</strong> (see useful links later in this
            article). Remember, you are in the arctic.
          </li>
        </ul>
        <p>This is especially true in Iceland:</p>
        <ul>
          <li>
            The roads indicated by your GPS (Google Maps and others…) can be
            rutted paths for many kilometers, without telephone coverage. In the
            event of a weather problem you can quickly be in trouble. Take a
            critical look at the route suggested by your GPS and favor the main
            roads.
          </li>
          <li>
            In addition to snowstorms, pay close attention to sandstorms in the
            south of the country.
          </li>
        </ul>
        <p>
          When you arrive there, plan to buy a snow shovel (about fifteen euros)
          in an auto center, it can to be of great service to you and to get you
          out of a difficult situation. Consider also to buy a tow rope.
        </p>
        <p>
          Finally, never take a risk and avoid snowy road verges, they are very
          soft: you can make your car fall there. Think about it if you have to
          do a U-turn.
        </p>
      </Section>
      <Section titre="Spotting and where to look?">
        <p>You will have to get away from cities and their light pollution.</p>
        <p>
          Auroras will appear on{" "}
          <strong>the northern horizon, from west to east</strong>, or at the
          zenith. So make sure at least some of these horizons are far away from
          any major city.
        </p>
        <p>
          Be more demanding during spotting if you plan to take quality photos:
          see article <Lien codePageCible="photographierAuroresBorealesEN" />.
        </p>
        <Image
          image={obtenirImage(images, "aurorePlageAbandonnee")}
          langue={page.langue}
        />
      </Section>
      <Section titre="During the night…">
        <p>
          Stay alert from approximately{" "}
          <strong>7 p.m. until at least midnight</strong> (an important activity
          can occur at this time: you will be at the heart of the auroral oval,
          see above).
        </p>
        <p>
          There will be several shockwaves: the aurora will lose its activity,
          then will get back some. Tell you that the auroras are capricious: you
          just have to want to go to bed because there is no more activity so
          that the auroras wakes up… Also, in case of strong solar storm, it may
          happen that the aurora is visible while the Sun has just set (it may
          still be daylight), until very late at night. One time I observed an
          aurora at dawn in Iceland around 5.30 p.m. which ended around 5 a.m.!
        </p>
        <p>
          If the cold hits you, take a break to warm up during low auroral
          activity: have a hot drink, get moving, put your hands under your
          armpits.
        </p>
      </Section>
      <Section titre="Northern lights forecast">
        <h3>Principle</h3>
        <p>
          It all starts with observing the Sun. It is constantly scrutinized by
          satellites like{" "}
          <Lien urlExterne="https://en.wikipedia.org/wiki/Solar_Dynamics_Observatory">
            SDO
          </Lien>{" "}
          (Solar Dynamics Observatory) and{" "}
          <Lien urlExterne="https://en.wikipedia.org/wiki/STEREO">STEREO</Lien>{" "}
          (Solar TErrestrial RElations Observatory) :
        </p>
        <ul>
          <li>
            SDO is responsible for monitoring the Sun. One of its missions is to
            take pictures of the Sun in different wavelengths to study the
            different layers of its atmosphere.
          </li>
          <li>
            STEREO A and B are two satellites which are located upstream and
            downstream of the Earth’s orbit around the Sun (at{" "}
            <Lien urlExterne="https://en.wikipedia.org/wiki/Lagrange_point">
              Lagrange points
            </Lien>{" "}
            L4 and L5). Their role is to scrutinize the faces of the Sun that we
            do not see, and make 3D models of solar flares.
          </li>
        </ul>
        <p>
          If a coronal mass ejection (read the article “
          <Lien codePageCible="comprendreAuroresBorealesEN" />
          ”) is detected, we will know it 8 minutes later, the time to the light
          to reach the satellites. It remains to know whether the trajectory of
          the solar plasma cloud will cross the Earth. Its direction and size
          can already be estimated from the STEREO satellite measurements.
        </p>
        <p>
          A first 3-days forecast is then made: the cloud of plasma travels
          between 400 and 750 km/s approximately, it will take 2 to 3 days to
          cross the Earth.
        </p>
        <p>
          2 to 3 days later, the cloud “pierces” the{" "}
          <Lien urlExterne="https://en.wikipedia.org/wiki/Advanced_Composition_Explorer">
            ACE
          </Lien>{" "}
          satellite (Advanced Composition Explorer). This satellite, located 1.5
          million kilometers in front of the Earth, on the path of the plasma
          clouds, is a real sentinel. The clouds that cross it will be analyzed:
          density, speed, magnetic field, etc. From these measurements, a more
          precise forecast for the next 1 to 2 hours will be calculated.
        </p>
        <div className="conteneur-video">
          <iframe
            src="https://player.vimeo.com/video/474174369"
            frameBorder="0"
            height="100%"
            width="300px"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <p>
          Above, a solar flare modeled by the STEREO satellites. The first view
          is a “top” view of the solar system. The second view is the same view,
          in profile, of the Earth. The large green spirals correspond to the
          lines of interplanetary magnetic fields emitted by Sun (the{" "}
          <Lien urlExterne="https://en.wikipedia.org/wiki/Parker_spiral">
            Parker spiral
          </Lien>
          ).
        </p>
        <h3>Kp index</h3>
        <p>
          The Kp index is a <strong>world index</strong> of the Earth’s magnetic
          field deformation. It can take values between 1 and 9. This index was
          introduced by the German geophysicist Julius Bartel in 1938:
        </p>
        <ul>
          <li>“K”: for “Kennziffer” in German (= “code number”).</li>
          <li>
            “p”: for “planetary”. The “Kp” is therefore a world average index
            calculated from different observation stations. We speak of
            “K-Index” for a local measurement.
          </li>
        </ul>
        <p>
          If a northern light occurs, then most likely the Earth’s magnetic
          field is strongly compressed by the solar storm that it undergoes.
          This is why this index is used to predict the power of the northern
          lights.
        </p>
        <p>
          <strong>Consequences:</strong>
        </p>
        <ul>
          <li>
            The higher the Kp index, the more intense the aurora should be (but
            this is not always true, see “The limits of forecasts” below).
          </li>
          <li>
            But the auroral oval will move south: you must not be too north to
            enjoy the aurora.
          </li>
          <li>We tell of a geomagnetic storm when the Kp is greater than 5.</li>
        </ul>
        <p>
          In northern Norway, a Kp between 2 and 3 is enough to see a northern
          light at the zenith. More to the south in Europe, a Kp greater than 8
          will be necessary to see a red aurora (around north).
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesIndiceKp")}
          langue={page.langue}
        />
        <h3>Other measures</h3>
        <p>
          Other measures may be given to you on apps or on specialized websites,
          sometimes synthesized into a single indicator called “Power of solar
          wind”:
        </p>
        <ul>
          <li>
            The magnetic orientation of the solar wind (Bz): this is the most
            important measure. It must be oriented south (negative Bz). A
            connection with the terrestrial magnetosphere will occur due to the
            north orientation of the magnetopause magnetic field (a “buffer”
            zone between the interplanetary magnetic field and the Earth’s
            magnetosphere). Following a complex path, solar wind particles will
            engulf themselves in the terrestrial magnetosphere and cause the
            northern lights (more explanations{" "}
            <Lien urlExterne="https://www-spof.gsfc.nasa.gov/Education/wmpause.html">
              here
            </Lien>
            ).
          </li>
          <li>
            Solar wind speed: the higher it is, the greater the impact on the
            magnetosphere will be violent, and the more beautiful the aurora
            will be (expressed in km/s). Values greater than 300 km/s are
            necessary to observe the northern lights.
          </li>
          <li>
            The density of the solar wind: the richer and more dense the cloud
            particles, the higher the solar wind speed, the more the impact on
            the magnetosphere will be strong and the more beautiful the aurora
            will be. This measurement is expressed in protons/cm³ and it must be
            greater than 1 to observe the Northern Lights.
          </li>
          <li>
            <Lien urlExterne="http://services.swpc.noaa.gov/images/ace-mag-swepam-24-hour.gif">
              These measurements are retrieved by the ACE satellite
            </Lien>{" "}
            (see above: “Principles”) and they are considered in the forecast
            calculation of the Kp index. Conclusion: focus on the Kp index
            forecasts :-)
          </li>
        </ul>
        <h3>The limits of the forecasts</h3>
        <p>
          The northern lights are capricious and their predictability is not an
          exact science:
        </p>
        <ul>
          <li>
            <strong>Spectacular auroras</strong> are formed only through
            magnetic reconnection that occurs in the tail of the magnetosphere
            (see article “<Lien codePageCible="comprendreAuroresBorealesEN" />
            ”). As this phenomenon is still poorly understood by scientists, the
            aurora forecast is therefore not 100% reliable.
          </li>
          <li>
            <strong>The “surprise” auroras</strong>: magnetic reconnection is
            not necessarily related to solar storms. These just create very
            favorable conditions for it. In addition, magnetic reconnection can
            also occur without solar storm.
          </li>
          <li>
            The <strong>update time</strong> of your apps. Northern lights are
            brief phenomena. The intense phases last about ten minutes (or more
            during a strong storm), which is often not sufficient for our apps
            to be warned in time. Indeed, a few minutes are always necessary
            before the results of the measurement center’s calculations be
            published, broadcasted, then pushed on our devices.
          </li>
        </ul>
      </Section>
      <Section titre="Phases and forms of northern lights">
        <h3>Height of the aurora on the horizon</h3>
        <p>
          The shape of the aurora you will observe will depend of your position:
        </p>
        <Image
          image={obtenirImage(
            images,
            "auroresBorealesObservationduneAuroreBoreale"
          )}
          langue={page.langue}
        />
        <h3>Phase 1 - The diffuse bow</h3>
        <p>
          Auroral activity is normal or low and the aurora is static in the sky.
          It is not very bright, its color is white. The aurora could be
          mistaken with a cloud. If you have any doubts, start a photographic
          exposure: if the cloud appears in green on the photo, then it’s an
          aurora. You have to wait, especially if the Kp forecast is good for
          the evening.
        </p>
        <h3>Phase 2 - The striped bow</h3>
        <p>
          A solar particles cloud begins to agitate the ionosphere. The aurora
          begins to dance softly across the sky, like a curtain flapping in the
          wind near an open window. The streaks of the aurora correspond to the
          Earth’s magnetic field lines. This phase is called the trigger phase.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesArcraye")}
          langue={page.langue}
        />
        <h3>Phase 3 - The expansion phase</h3>
        <p>
          Activity is at its maximum. If the solar storm is intense, the aurora
          can occupy the whole sky and move at incredible speeds. There may be
          several expansion phases during a night. Again, don’t go to bed yet,
          even if the northern light seems to be extinguishing.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesPhaseactive")}
          langue={page.langue}
        />
        <h3>Phase 5 - The coronal aurora</h3>
        <p>
          Coronal aurora is often the end of the most active phase of an intense
          solar storm night. It is the most spectacular phase of the aurora, the
          one that you will remember for a long time because its visual power is
          extremely intense.
        </p>
        <p>
          The aurora is very bright. All of its streaks seem to come from one
          same point, it swirls around your head: it has the shape of a crown,
          hence its name. It occurs at the activity peak of the night, around 12
          a.m.-1 a.m., when you are in the middle of the auroral oval. It
          requires a strong solar storm for it to occur. So it is quite rare,
          and above all it does not last very long (5 minutes).
        </p>
        <p>
          If your camera is not ready, don’t try to use it: the phenomenon will
          be brief. So enjoy the show.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesAurorecoronale")}
          langue={page.langue}
        />
        <h3>Phase 6 : the pulsating aurora</h3>
        <p>
          The pulsating aurora often marks the end of the night observations.
          This type of aurora is part of the so-called “recovery phase”. It
          follows a very strong solar storm, they are therefore rare. I saw them
          each time around 2 or 3 a.m..
        </p>
        <p>
          Once upon a time I saw an aurora regain intensity after a pulsatile
          phase. The speed and intensity of the pulsations can change: sometimes
          very slow, sometimes extremely fast (several flashes per second).
        </p>
        <p>
          Here is a pulsating aurora timelapse made in January 2013 in the
          Lofoten Islands in Norway:
        </p>
        <div className="conteneur-video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/1ma6500fsIw"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Section>
      <Section titre="In summary…">
        <p>Keep the following tips in mind:</p>
        <ul>
          <li>Watch for Kp forecasts.</li>
          <li>
            3-day Kp forecasts: they are useful for spotting future photo spots
            and anticipate possible trips in the country where you are.
          </li>
          <li>
            Forecasts for the evening: if they are good, eat a heavy meal and
            plan to stay outside until at least midnight. If they are not good
            and the sky is clear: take a regular look outside, a “surprise”
            aurora borealis can occur at any time (see “the limits of the
            forecasts”).
          </li>
          <li>
            Watch the weather: clear sky gaps will be necessary. Check the
            temperature and wind forecast to equip yourself accordingly.
          </li>
          <li>
            On a busy night, never go to bed before midnight. Even though the
            aurora seems to have died out, it can become very powerful again at
            any time.
          </li>
        </ul>
      </Section>
      <Section titre="Useful links">
        <h3>Weather and cloud cover</h3>
        <ul>
          <li>
            <Lien urlExterne="http://www.yr.no">
              Weather forecast in Norway and Sweden.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://en.vedur.is/">
              Weather forecast in Iceland.
            </Lien>
          </li>
          <li>
            <Lien codePageCible="previsionsCouvertureNuageuse">
              Cloud cover forecast.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://www.yr.no/satellitt/europa_animasjon.html">
              Satellite animation in Northern Europe
            </Lien>{" "}
            (Sat24 does not work for northern Scandinavia).
          </li>
        </ul>
        <h3>Traffic conditions</h3>
        <ul>
          <li>
            <Lien urlExterne="https://www.trafikverket.se/en/startpage/operations/Operations-road/Traffic-information/">
              In Sweden.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.road.is/">In Iceland.</Lien>
          </li>
        </ul>
        <h3>Solar weather</h3>
        <ul>
          <li>
            <Lien urlExterne="http://www.swpc.noaa.gov/products/3-day-forecast">
              Kp forecasts for the next 3 days.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.swpc.noaa.gov/products/planetary-k-index">
              Kp forecasts for the next hours.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://twitter.com/aurora_alerts">
              Aurora alerts on Twitter.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="http://www.spaceweather.com/">
              Space Weather.
            </Lien>
          </li>
          <li>
            <Lien urlExterne="https://www.spaceweatherlive.com">
              Space Weather Live.
            </Lien>
          </li>
        </ul>
      </Section>
      <Section titre="Useful applications for smartphones">
        <p>Some useful applications for smartphones:</p>
        <ul>
          <li>
            iOS :{" "}
            <Lien urlExterne="https://apps.apple.com/fr/app/solar-monitor-3/id1317284385">
              Solar Monitor
            </Lien>
            . Weather report :{" "}
            <Lien urlExterne="https://itunes.apple.com/us/app/windy-wind-and-waves-forecast/id1161387262?mt=8">
              Windy
            </Lien>{" "}
            (cloud cover),{" "}
            <Lien urlExterne="https://itunes.apple.com/fr/app/yr/id490989206?mt=8">
              yr.no
            </Lien>
            …
          </li>
          <li>
            Android :{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=com.jrustonapps.myauroraforecast">
              My Aurora Forecast
            </Lien>
            ,{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=com.spaceweatherlive.app">
              Space weather live
            </Lien>
            . Weather forecast:{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=com.windyty.android&hl=fr">
              Windy
            </Lien>{" "}
            (cloud cover),{" "}
            <Lien urlExterne="https://play.google.com/store/apps/details?id=no.nrk.yr&hl=fr">
              yr.no
            </Lien>
            …
          </li>
        </ul>
      </Section>
    </LayoutTutorielEN>
  )
}
